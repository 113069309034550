import React, { useState } from "react";
import { resumeBullets } from './data';
import CV from '../../assets/cv.pdf';
import { HiDownload } from 'react-icons/hi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptop, faSchool, faBusinessTime } from "@fortawesome/free-solid-svg-icons";
import ResumeDetails from './ResumeDetails';
import "./Resume.css";

const Resume = () => {
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carouselOffsetStyle, setCarouselOffsetStyle] = useState({});

  const handleCarousel = (index) => {
    let offsetHeight = 360;

    let newCarouselOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarouselOffsetStyle(newCarouselOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousel(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <FontAwesomeIcon icon={bullet.logo === "school" ? faSchool : bullet.logo === "briefcase" ? faBusinessTime : faLaptop} />
        &nbsp;&nbsp;
        <b><p className="bullet-label">{bullet.label}</p></b>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carouselOffsetStyle.style}
        className="resume-details-carousel"
      >
        {ResumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };


  return (
    <section id="resume">
      <div className="resume-container">
        <div className="resume-content">
        </div>
        <h2>Resumé</h2>
        <br />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
        <br />
        <br />
        <a href={CV} download className='btn primary'>Download CV <HiDownload /></a>
      </div>
    </section>
  );
};

export default Resume;
