const programmingSkills = [
    { skill: "C#", ratingPercentage: 85 },
    { skill: "SQL", ratingPercentage: 85 },
    { skill: "React JS", ratingPercentage: 65 },
    { skill: "GraphQL", ratingPercentage: 70 },
    { skill: "RabbitMQ", ratingPercentage: 85 },
    { skill: "Docker", ratingPercentage: 65 },
    { skill: "HTML", ratingPercentage: 75 },
    { skill: "CSS", ratingPercentage: 75 },
];

const resumeBullets = [
    { label: "Education", logo: "school" },
    { label: "Work History", logo: "briefcase" },
    { label: "Skills and Competencies", logo: "laptop" }
];


export {
    programmingSkills, resumeBullets
};

