import AboutImage from '../../assets/about.jpg';
import data from './data';
import Card from '../../components/Card';
import './about.css';

const About = () => {
    return (
        <section id="about">
            <div className="container about__container">
                <div className="about__left">
                    <div className="about__portrait">
                        <img src={AboutImage} alt="About" />
                    </div>
                </div>
                <div className="about__right">
                    <h2>About Me</h2>
                    <p/>
                    <p>
                        Hi, my name is Joseph Attard. I am a software developer with a focus on backend technologies and with a big passion for data, sports and the latest software and tools.
                    </p>
                    <p>
                        I have worked the Maltese igaming and financial sectors for the past 7 years and I am always striving for continuous self-development. I like to experiment with frontend technologies in my spare time, mainly React and Progressive Web Apps.
                    </p>
                    <p>
                        Apart from turning coffee into code, I have several other hobbies, particularly the ones mentioned hereunder.
                    </p>
                    <div className="about__cards">
                        {
                            data.map(item => (
                                <Card key={item.id} className="about__card">
                                    <span className="about__card-icon">{item.icon}</span>
                                    <h5>{item.title}</h5>
                                    <small>{item.desc}</small>
                                </Card>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About