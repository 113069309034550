import ResumeHeading from "./ResumeHeading";
import { programmingSkills } from './data';

const ResumeDetails = [
    <div className="resume-screen-container" key="education">
        <ResumeHeading
            heading={"MCAST"}
            subHeading={
                "Bachelor of Science in Software Development - First Class Honours"
            }
            fromDate={"2014"}
            toDate={"2016"}
        />

        <ResumeHeading
            heading={"MCAST"}
            subHeading={"BTEC Higher National Diploma in Software Development"}
            fromDate={"2012"}
            toDate={"2014"}
        />
        <ResumeHeading
            heading={"Sir MichelAngelo Refalo"}
            subHeading={"Matriculation Certificate - Advanced Level in Pure Mathematics and Computing"}
            fromDate={"2008"}
            toDate={"2011"}
        />
    </div>,

    <div className="resume-screen-container" key="work-experience">
        <div className="experience-container">
            <ResumeHeading
                heading={"MeDirect Bank Malta"}
                subHeading={"Senior Software Developer"}
                fromDate={"2023"}
                toDate={"Present"}
            />
            <br />
            <ResumeHeading
                heading={"Betsson Group"}
                subHeading={"Senior Backend Developer - Sportsbook"}
                fromDate={"2020"}
                toDate={"2023"}
            />
            <br />
            <ResumeHeading
                heading={"Helio Gaming"}
                subHeading={"Backend Software Developer"}
                fromDate={"2019"}
                toDate={"2020"}
            />
            <br />
            <ResumeHeading
                heading={"Bit8"}
                subHeading={"Software Developer"}
                fromDate={"2015"}
                toDate={"2019"}
            />
        </div>
    </div>,

    <div
        className="resume-screen-container programming-skills-container"
        key="programming-skills"
    >
        {programmingSkills.map((skill, index) => (
            <div className="skill-parent" key={index}>
                <span>{skill.skill}</span>
                <div className="skill-percentage">
                    <div
                        style={{ width: skill.ratingPercentage + "%" }}
                        className="active-percentage-bar"
                    ></div>
                </div>
            </div>
        ))}
    </div>
];

export default ResumeDetails;