import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiFillFacebook} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    {id: 3, link: '#resume', title: 'Resumé'},
    {id: 4, link: '#portfolio', title: 'Projects'},
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [
    {id: 1, link: 'https://www.linkedin.com/in/josephattard/', icon: <AiFillLinkedin/>},
    {id: 2, link: 'https://www.instagram.com/jos.attard/', icon: <AiOutlineInstagram/>},
    {id: 3, link: 'https://twitter.com/JosAttard', icon: <AiOutlineTwitter/>},
    {id: 4, link: 'https://www.facebook.com/jos.attard/', icon: <AiFillFacebook/>}
]