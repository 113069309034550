
import { BiFootball, BiBook } from 'react-icons/bi'
import { FaRunning } from 'react-icons/fa'


const data = [
    { id: 1, icon: <BiFootball />, title: 'Football', desc: 'Avid Manchester United fan, always.' },
    { id: 2, icon: <FaRunning />, title: 'Running', desc: 'When not running code, I\'m running kilometres.' },
    { id: 3, icon: <BiBook />, title: 'Reading', desc: 'Mostly on my kindle, and audiobooks.' }

]

export default data;