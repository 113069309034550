import Image1 from '../../assets/project-3.png'
import Image2 from '../../assets/project-2.png'

const data = [
    {
        id: 1,
        category: 'React / PWA',
        image: Image1,
        title: "Find a Joke!",
        desc: "A small app developed in React.js where users can choose a topic and spawn a joke on the fly! This project is still being developed and new features will be available soon.",
        demo: 'https://findajoke.com',
    },
    {
        id: 2,
        category: 'GraphQL',
        image: Image2,
        title: "Tennis GraphQL API",
        desc: "A personal project using .NET Core and GraphQL.NET providing an API whereby all data from the ATP men's tennis tour dating back to the year 2000 can be queried and filtered. The data is sourced from www.tennis-data.co.uk and cleansed and imported into an SQL database. The data can be filtered through numerous criteria such as match winner, location, year, court surface, etc.",
        demo: 'https://tennisgraphql.com',
    }
]


export default data