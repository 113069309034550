import HeaderImage from '../../assets/passport.jpg'
import data from './data'
import './header.css'

const Header = () => {
  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile">
          <img src={HeaderImage} alt="Header Portrait"></img>
        </div>
        <h3>Joseph Attard</h3>
        <p>
          Senior Software Developer
        </p>
        <div className="header__cta">
          <a href="#resume" className='btn primary'>Resumé</a>
          <a href="#contact" className='btn light'>Let's Talk</a>
        </div>
        <div className="header__socials">
          {
            data.map(item=> <a key={item.id} href={item.link} target="_blank"
            rel="noopener noreferrer">{item.icon}</a>)
          }
        </div>
      </div>
      </header>
  )
}

export default Header