import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiFillFacebook} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'

const data = [
    {id: 1, link: 'https://www.linkedin.com/in/josephattard/', icon: <AiFillLinkedin/>},
    {id: 2, link: 'https://www.instagram.com/jos.attard/', icon: <AiOutlineInstagram/>},
    {id: 3, link: 'https://twitter.com/JosAttard', icon: <AiOutlineTwitter/>},
    {id: 4, link: 'https://www.facebook.com/jos.attard/', icon: <AiFillFacebook/>}
]

export default data